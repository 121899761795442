<template>
  <EntityTable
    :api-url="apiUrl"
    :columns="columns"
    :title="title"
    :edit-page="editPage"
    :delete-url="deleteUrl"
    :filters="filters"
    :actionsUrl="actionsUrl"
    :row-color-function="rowColorFunction"
    edit-page-router="admin-mod"
  ></EntityTable>
</template>
<script>
import { defineComponent } from "vue";
import EntityTable from "@/components/EntityTable";

export default defineComponent({
  name: "admin-mods",
  components: {
    EntityTable,
  },
  data: function () {
    return {
      apiUrl: "admin/mods",
      deleteUrl: "/api/admin/mods/delete/",
      actionsUrl: "admin/mods/actions",
      rowColorFunction: function (item) {
        return !item.active;
      },

      editPage: "/admin-dashboard/mod/",
      title: "Mods",
      columns: [
        {
          name: "Mod Id",
          value: "id",
        },
        {
          name: "Title",
          value: "title",
        },
        {
          name: "Authors",
          value: "authors",
          processor: function (item) {
            return item.map((author) => author.name).join(", ");
          },
        },
        {
          name: "Game",
          value: "game",
          processor: function (item) {
            switch (item) {
              case 0:
                return "FE";
              case 1:
                return "SE";
              case 2:
                return "TFE/TSE";
              case 3:
                return "SS2";
            }
            return "Error";
          },
        },
        {
          name: "Type",
          value: "type",
          processor: function (item) {
            switch (item) {
              case 0:
                return "Map";
              case 1:
                return "Mod";
              case 2:
                return "Skin";
              case 3:
                return "Resource";
              case 4:
                return "DLL";
              case 5:
                return "Weapon";
            }
            return "Error";
          },
        },
        {
          name: "Subcategory",
          value: "subcategory",
          processor: function (item) {
            switch (item) {
              case 0:
                return "Single";
              case 1:
                return "Coop";
              case 2:
                return "Single/Coop";
              case 3:
                return "DM";
              case 4:
                return "ANY";
            }
            return "Error";
          },
        },
        {
          name: "Size",
          value: "size",
          processor: function (bytes) {
            var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
            if (bytes == 0) return "0 Byte";
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
          },
        },
        {
          name: "Release date",
          value: "originalCreatedDate",
          processor: function (string) {
            if (!string) return "NULL";

            return string.substring(0, 10);
          },
        },
      ],
      filters: [
        {
          name: "Mod Name",
          value: "query",
        },
        {
          name: "Active",
          value: "active",
          type: "select",
          options: [
            {
              value: 0,
              title: "False",
            },
            {
              value: 1,
              title: "True",
            },
          ],
        },
        {
          name: "Has release date",
          value: "hasOriginalDate",
          type: "select",
          options: [
            {
              value: 0,
              title: "False",
            },
            {
              value: 1,
              title: "True",
            },
          ],
        },
        {
          name: "Game Type",
          value: "gameType",
          type: "select",
          options: [
            {
              value: 0,
              title: "FE",
            },
            {
              value: 1,
              title: "SE",
            },
          ],
        },
        {
          name: "Mod Type",
          value: "modType",
          type: "select",
          options: [
            {
              value: 0,
              title: "Map",
            },
            {
              value: 1,
              title: "Mod",
            },

            {
              value: 2,
              title: "Skin",
            },

            {
              value: 3,
              title: "Resource",
            },
            {
              value: 4,
              title: "DLL",
            },
          ],
        },
        {
          name: "Subcategory",
          value: "subcategory",
          type: "select",
          options: [
            {
              value: 0,
              title: "Single",
            },
            {
              value: 1,
              title: "Coop",
            },

            {
              value: 2,
              title: "Single & Coop",
            },

            {
              value: 3,
              title: "DM",
            },
            {
              value: 4,
              title: "Any",
            },
          ],
        },
        {
          name: "Author",
          value: "authorId",
          type: "suggestion",
          url: "admin/authors/suggestion",
        },
      ],
    };
  },

  methods: {},
});
</script>
